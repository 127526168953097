header {
  height: 72px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  z-index: 10;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(44, 39, 31, 0.6);
    backdrop-filter: blur(6px);
  }
  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header__navigation {
    display: flex;
    align-items: center;
    height: 100%;
    transition: max-height 300ms ease;
    .navigation__item {
      margin-left: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #a39a8b;
      height: 100%;
      display: flex;
      align-items: center;
      transition: color 300ms ease;
      & > a {
        height: 100%;
        display: inline-flex;
        align-items: center;
      }
      &.sub {
        /*&:hover {
          .navigation__submenu {
            max-height: 400px;
          }
        }*/
        .navigation__submenu {
          overflow: hidden;
          position: absolute;
          top: 70px;
          left: 0;
          right: 0;
          max-height: 0;
          background: rgba(44, 39, 31, 0.9);
          backdrop-filter: blur(6px);
          display: flex;
          justify-content: center;
          transition: max-height 300ms ease-in-out;

          ul {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            margin: 40px -24px 0 0;
            li {
              width: calc(16.66666% - 24px);
              margin: 0 24px 40px 0;
              &:hover {
                span {
                  color: $primary_300;
                }
              }
              img {
                width: 80px;
                height: 80px;
              }
              span {
                font-weight: 1000;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                color: $primary_500;
                display: block;
                margin-top: 16px;
                transition: color 250ms ease;
              }
            }
          }
        }
      }
      &.orange {
        color: $primary_500;
      }
      & > span {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      }
      &:hover {
        color: $black_50;
      }
      .icon-arrow_down {
        width: 10px;
        color: currentColor;
        fill: currentColor;
        margin-left: 8px;
      }
    }
  }
  .header__navigation_button {
    background-color: transparent;
    width: 48px;
    height: 48px;
    display: none;
    justify-content: center;
    align-items: center;
    margin-right: -10px;
    i {
      width: 18px;
      height: 2px;
      border-radius: 2px;
      background-color: $background_light;
      position: relative;
      transition: background-color 250ms ease;
      &::before,
      &::after {
        content: '';
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background-color: $background_light;
        position: absolute;
        left: 0;
        right: 0;
        transition: top 250ms ease, transform 250ms ease;
      }
      &::before {
        top: -5px;
      }
      &::after {
        bottom: -5px;
      }
    }
  }
  &.show-menu {
    .header__navigation {
      max-height: 100vh;
    }
    .header__navigation_button {
      i {
        background-color: transparent;
        &::before {
          top: 0;
          transform: rotate(45deg);
        }
        &::after {
          bottom: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  /* Mobile styles */
  @media screen and (max-width: 1200px) {
    .header__navigation {
      .navigation__item {
        &.sub {
          .navigation__submenu {
            ul {
              li {
                width: calc(20% - 24px);
              }
            }
          }
        }
        /*&.sub:hover {
          .navigation__submenu {
            max-height: 600px;
          }
        }*/
      }
    }
  }

  @media screen and (max-width: 768px) {
    height: 56px;
    .header__navigation_button {
      display: flex;
    }
    .header__navigation {
      position: absolute;
      top: 56px;
      left: 0;
      right: 0;
      flex-direction: column;
      height: auto;
      background: rgba(44, 39, 31, 0.9);
      backdrop-filter: blur(18px);
      padding-top: 0;
      max-height: 0;
      overflow: auto;
      .navigation__item {
        margin: 0 0 24px 0;
        font-weight: 700;
        flex-wrap: wrap;
        justify-content: center;
        &:first-child {
          margin-top: 24px;
        }
        &.sub {
          .navigation__submenu {
            position: static !important;
            width: 100%;
            ul {
              flex-direction: column;
              flex-wrap: nowrap;
              position: relative;
              padding: 12px;
              margin: 8px 0 0 0;
              align-items: stretch;
              &::before {
                content: '';
                display: block;
                background: url('../../img/backgrounds/body_pattern.png') repeat 0 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.05;
                z-index: -1;
              }
              li {
                width: auto !important;
                background: rgba(82, 75, 61, 0.8);
                backdrop-filter: blur(1.4717px);
                border-radius: 4px;
                margin: 0 0 8px 0;
                a {
                  display: flex;
                  align-items: center;
                  padding: 12px;
                  img {
                    width: 40px;
                    height: 40px;
                    margin-right: 8px;
                  }
                  span {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.no-touch-device {
  header .header__navigation .navigation__item.sub:hover .navigation__submenu {
    max-height: 400px;
    @media screen and (max-width: 768px) {
      max-height: 600px;
    }
    @media screen and (max-width: 768px) {
      max-height: 700px;
      background: transparent;
      backdrop-filter: blur(0);
    }
  }
}

.touch-device {
  header .header__navigation .navigation__item.sub.open .navigation__submenu {
    max-height: 400px;
    @media screen and (max-width: 768px) {
      max-height: 600px;
    }
    @media screen and (max-width: 768px) {
      max-height: 700px;
      background: transparent;
      backdrop-filter: blur(0);
    }
  }
}
