footer {
  background-color: #524b3d;
  padding: 54px 0 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black_50;
  .footer__grid {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    h3 {
      font-variation-settings: 'wdth' 145, 'wght' 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: $color_text;
      margin-bottom: 8px;
    }
    a {
      color: $primary_500;
    }
    .footer__social {
      display: flex;
      align-items: center;
      color: $color_text;
      margin-bottom: 8px;
      a {
        margin-right: 16px;
      }
      .icon {
        width: 24px;
        height: 24px;
        fill: $color_text;
      }
      & + span {
        color: $color_text;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .footer__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 40px 0 56px;
    .footer__grid {
      grid-template-columns: 1fr;
      .footer__grid_item {
        &:last-child {
          margin-bottom: 0;
          margin-top: 16px;
          text-align: center;
        }
        h3 {
          margin-bottom: 8px;
        }
      }
      .footer__social {
        margin: auto;
        display: flex;
        justify-content: center;
        a {
          margin: 0 8px;
        }
      }
    }
  }
}
