.company {
  .company__hero {
    padding-top: 218px;
    padding-bottom: 300px;
    margin-bottom: 72px;
    position: relative;
    .company__pattern {
      position: absolute;
      z-index: -1;
      left: 45%;
      bottom: 0;
      transform: translateX(-45%);
    }
    .company__pattern_mobile {
      display: none;
      position: absolute;
      z-index: -1;
      top: -0;
      left: -10%;
      right: -10%;
      bottom: 0;
      width: 120%;
      height: 100%;
      object-fit: cover;
    }
    h1 {
      max-width: 66%;
      margin-bottom: 32px;
      span {
        display: block;
        color: $primary_500;
      }
    }
    ul {
      max-width: 66%;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $black_400;
      li {
        width: calc(50% - 12px);
      }
    }
  }
  .company__principles {
    h2 {
      font-weight: 1000;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $black_50;
      margin-bottom: 90px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 108px;
      li {
        width: calc(50% - 124px);
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $black_200;
        margin-right: 124px;
        h3 {
          font-weight: 1000;
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: $primary_500;
          background: rgba(82, 75, 61, 0.7);
          border-radius: 4px;
          margin-bottom: 32px;
          padding: 56px 16px 16px;
          position: relative;
        }
      }
      &:last-child {
        justify-content: flex-end;
        li {
          margin-right: 0;
          margin-left: 124px;
          h3 {
            text-align: right;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .company__principles {
      ul {
        li {
          width: calc(50% - 64px);
          margin-right: 64px;
        }
        &:last-child {
          li {
            margin-left: 64px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .company__hero {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      max-height: 1000px;
      .company__pattern {
        display: none;
      }
      .company__pattern_mobile {
        display: block;
      }
      h1 {
        max-width: initial;
        width: 100%;
      }
      ul {
        max-width: initial;
        display: block;
        li {
          width: auto;
          margin-bottom: 16px;
        }
      }
    }
    .company__principles {
      padding-bottom: 56px;
      h2 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 32px;
      }
      ul {
        display: block;
        padding: 0;
        margin: 0;
        li {
          width: 100%;
          margin: 0 0 24px 0 !important;
          h3 {
            font-size: 28px;
            line-height: 36px;
            padding: 16px;
            margin-bottom: 16px;
            text-align: left !important;
            .circle {
              display: none;
            }
          }
        }
      }
    }
  }
}
