.contact {
  position: relative;
  article {
    width: 50%;
    margin-left: auto;
    padding-top: 232px;
    padding-left: 24px;
    h1 {
      margin-bottom: 80px;
    }
    .contact__list {
      margin-bottom: 40px;
      & > div {
        width: calc(50% - 12px);
        b {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          text-transform: uppercase;
          color: $color_text;
          display: block;
          margin-bottom: 4px;
        }
        a {
          font-weight: 600;
          font-size: 28px;
          line-height: 40px;
          color: $primary_500;
        }
      }
    }
    .contact__main_text {
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      color: $black_50_2;
      margin-bottom: 24px;
    }
    .contact__secondary_text {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 188px;
      & > div {
        width: calc(50% - 12px);
      }
    }
  }
  .contact__pattern {
    position: absolute;
    right: calc(50% + 24px);
    z-index: -1;
  }

  @media screen and (max-width: 1200px) {
    article {
      .contact__list {
        display: block;
        & > div {
          width: auto;
          margin-bottom: 16px;
        }
      }
      .contact__secondary_text {
        display: block;
        & > div {
          width: auto;
          margin-bottom: 16px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .contact__pattern {
      left: 0;
      right: auto;
      opacity: 0.2;
    }
    article {
      width: auto;
      padding: 0;
      margin: 0;
      .contact__pattern_hero {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 86px 0;
        h1 {
          margin-bottom: 24px;
        }
        .contact__list {
          display: block;
          & > div {
            width: auto;
            margin-bottom: 16px;
            b {
              font-weight: 700;
              font-size: 20px;
              line-height: 28px;
              margin-bottom: 2px;
            }
            a {
              font-weight: 700;
              font-size: 24px;
              line-height: 36px;
            }
          }
        }
      }
      .contact__secondary_text {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 56px;
        & > div {
          width: auto;
          margin-bottom: 24px;
        }
      }
    }
  }
}
