$primary_300: #f8b572;
$primary_500: #fe9832;
$color_text: #a39a8b;
$black_50: #fcf3e2;
$black_50_2: #e5dccb;
$black_100: #e5dccb;
$black_200: #cec5b5;
$black_300: #b8b0a0;
$black_400: #a39a8b;
$black_700: #655d4f;
$black_900: #2c271f;
$background_dark: #1e1a12;
$background_light: #fffdfa;
$dark: #1e1a12;
