.services {
  .services__hero {
    background-color: $background_dark;
    clip-path: polygon(0% 0%, calc(100% + 70px) 0%, calc(100% - 320px) 100%, 0% 100%);
    padding-top: 192px;
    padding-bottom: 164px;
    position: relative;
    .services__hero_animation {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%) scale(1.7);
      z-index: -1;
    }
    .wrapper {
      max-width: 874px;
    }
    h1 {
      letter-spacing: 0.02em;
      color: #fe9832;
    }
    .services__hero_description {
      margin-top: 24px;
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $black_200;
      p {
        width: calc(50% - 12px);
      }
    }
  }
  .services__description_list {
    padding-top: 134px;
    ul {
      li {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $black_200;
        margin-right: 203px;
        margin-bottom: 120px;
        &:nth-child(even) {
          margin-right: 0;
          margin-left: 203px;
          h3 {
            text-align: right;
          }
        }
        h3 {
          max-width: 78%;
          font-weight: 1000;
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: $black_50;
          background: rgba(82, 75, 61, 0.7);
          border-radius: 4px;
          margin-bottom: 56px;
          padding: 16px;
          position: relative;
        }
        & > section {
          display: grid;
          grid-template-columns: 1.7fr 1fr 1fr;
          column-gap: 24px;
          a {
            color: $primary_500;
            text-decoration: underline;
          }
          & > p:first-child {
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
            color: $primary_500;
          }
        }
      }
    }
  }
  .services__swiper {
    position: relative;
    &::after {
      content: '';
      display: block;
      width: 200px;
      height: 100%;
      background: linear-gradient(270deg, #2c271f 0%, rgba(44, 39, 31, 0) 100%);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
    .swiper {
      margin: 0 112px;
      overflow: visible;
      .swiper-slide {
        border: 1px solid #e5dccb;
        border-radius: 2px;
        height: auto;
        h3 {
          padding: 20px;
          font-weight: 1000;
          font-size: 24px;
          line-height: 32px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: $primary_500;
          border-bottom: 1px solid #e5dccb;
          min-height: 105px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
        ul {
          padding: 20px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 16px;
          row-gap: 8px;
          li {
            background: rgba(82, 75, 61, 0.8);
            border-radius: 2px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: $black_50;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 8px;
          }
        }
      }
      .swiper-pagination {
        top: calc(100% + 40px);
        .swiper-pagination-bullet {
          border: 1px solid $primary_500;
          width: 12px;
          height: 12px;
          opacity: 1;
          background: transparent;
          margin: 0 3px;
          &.swiper-pagination-bullet-active {
            background-color: $primary_500;
          }
        }
      }
    }
  }
  .services__our-research {
    margin-top: 120px;
    overflow: hidden;
    .wrapper {
      display: flex;
      position: relative;
      padding-top: 80px;
      padding-bottom: 90px;
      .services__our-research_bg {
        height: 100%;
        width: 1683px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 40px;
        z-index: -1;
        background: #1e1a12;
        clip-path: polygon(330px 0%, 100% 0%, calc(100% - 330px) 100%, 0% 100%);
        img {
          position: absolute;
          width: 100vw;
          top: 0;
          left: 330px;
          z-index: -1;
          transform: translate(-50%, -50%) scale(1.7);
        }
      }
      ul {
        /*display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 24px;
        row-gap: 28px;*/
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 600px;
        width: 50%;
        flex-shrink: 0;
        li {
          margin-right: 24px;
          margin-bottom: 28px;
          width: calc(50% - 24px);
          &:nth-child(1) {
            margin-top: 64px;
          }
        }
      }
      section {
        margin-left: 136px;
        & > h2 {
          font-weight: 900;
          font-size: 48px;
          line-height: 56px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: $black_50;
          margin-bottom: 24px;
        }
        & > p {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          color: $black_200;
          margin-bottom: 32px;
        }
        & > a {
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          text-transform: uppercase;
          color: $primary_500;
        }
      }
    }
  }
  .services__other-services {
    margin-top: 120px;
    background-color: #fffdfa;
    padding: 48px;
    h2 {
      border: 2px solid #2c271f;
      border-radius: 2px;
      padding: 16px;
      font-weight: 1000;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $black_900;
      margin-bottom: 40px;
      max-width: 872px;
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 24px;
      li {
        a {
          display: block;
          height: 100%;
        }
        .icon {
          width: 120px;
          height: 120px;
          fill: currentColor;
        }
        span {
          display: block;
          margin-top: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .services__hero {
      .services__hero_description {
        padding-right: 25%;
      }
    }
    .services__description_list {
      ul {
        li {
          margin-right: 0;
          &:nth-child(even) {
            margin-left: 0;
          }
          h3 {
            max-width: initial;
          }
        }
      }
    }
    .services__our-research {
      .wrapper {
        section {
          margin-left: 24px;
        }
      }
    }
    .services__other-services {
      ul {
        li {
          a {
            img {
              width: 100px;
              height: 100px;
            }
            h3 {
              font-size: 22px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .services__hero {
      clip-path: none;
      transform: translate3d(0, 0, 0);
      padding: 0;
      height: 100vh;
      max-height: 1000px;
      display: flex;
      justify-content: center;
      .services__hero_animation {
        height: 100%;
        width: auto;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .services__description_list {
      padding-top: 56px;
      ul {
        li {
          margin: 0 0 64px 0 !important;
          padding: 0 !important;
          font-size: 16px;
          line-height: 24px;
          h3 {
            max-width: initial;
            padding: 16px;
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px;
            text-align: left !important;
            .circle {
              display: none;
            }
          }
          & > section {
            display: block;
            p {
              margin-bottom: 16px;
              &:first-child {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    .services__swiper {
      .swiper {
        margin: 0;
        .swiper-slide {
          ul {
            grid-template-columns: 1fr;
          }
        }
      }
      &::after {
        display: none;
      }
    }
    .services__our-research {
      .wrapper {
        flex-direction: column;
        padding-top: 56px;
        padding-bottom: 56px;
        .services__our-research_bg {
          left: -20%;
          img {
            width: auto;
            height: 150%;
          }
        }
        & > section {
          order: 1;
          margin: 0;
          h2 {
            font-size: 40px;
            line-height: 48px;
          }
        }
        & > ul {
          order: 2;
          width: 100%;
          margin-top: 32px;
          display: block;
          max-height: initial;
          li {
            width: auto;
            margin: 0 0 24px 0 !important;
            &:last-child {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
    .services__other-services {
      margin-top: 0;
      padding: 56px 0;
      h2 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 32px;
      }
      ul {
        grid-template-columns: 1fr;
        grid-row-gap: 16px;
        li {
          a {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
