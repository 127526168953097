html {
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  background: #2c271f;
  position: relative;
  &::before {
    content: '';
    display: block;
    background: url('../img/backgrounds/body_pattern.png') repeat 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.05;
    z-index: -1;
  }
  font-family: 'Roboto Flex', sans-serif;
  color: $color_text;
}

main {
  overflow: hidden;
}

.wrapper {
  max-width: 1320px;
  margin: auto;
  padding: 0 24px;
  box-sizing: content-box;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }
}

* {
  box-sizing: border-box;
}

a {
  color: currentColor;
  text-decoration: none;
}

h1 {
  //font-weight: 900;
  font-variation-settings: 'wdth' 145, 'wght' 900;
  font-size: 64px;
  line-height: 72px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $black_50;
  /* Mobile styles */
  @media screen and (max-width: 768px) {
    font-variation-settings: 'wdth' 145, 'wght' 1000;
    font-size: 36px;
    line-height: 44px;
  }
}

h2 {
  font-variation-settings: 'wdth' 145, 'wght' 1000;
}

h3,
h4,
h5 {
  font-variation-settings: 'wdth' 145, 'wght' 1000;
}

.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.space-between {
  justify-content: space-between;
}
.center {
  text-align: center;
  justify-content: center;
}
.align-items-center {
  align-items: center;
}

.circle {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  background-color: $primary_500;
  border-radius: 50%;
  &::before {
    content: '';
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    border: 2px solid $primary_500;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    content: '';
    display: block;
    width: 54px;
    height: 54px;
    position: absolute;
    border: 2px solid $primary_500;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.circle_right {
    left: auto;
    right: -5px;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  padding: 0 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border-radius: 8px;
  transition: background-color 250ms ease, color 250ms ease, border-color 250ms ease;
  min-width: 200px;
  background-color: transparent;
  &.small {
    height: 38px;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    min-width: auto;
  }
  &.contained {
    color: $black_900 !important;
    background-color: $primary_500;
    &:hover {
      background-color: $primary_300;
    }
    &:active {
      background-color: $primary_500;
    }
  }
  &.outline {
    border: 2px solid $primary_500;
    border-radius: 8px;
    color: $primary_500;
    &:hover {
      border-color: $primary_300;
      color: $primary_300;
    }
  }
}

.title {
  &.contained {
    padding: 16px;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $black_50;
    background: rgba(82, 75, 61, 0.6);
    backdrop-filter: blur(6px);
    margin-bottom: 56px;
    min-width: auto;
  }
}

.card-item {
  border-radius: 4px;
  display: block;
  border: 2px solid transparent;
  transition: border-color 250ms ease;
  &.card-large {
    padding: 40px 40px 28px 40px;
    img {
      width: 120px;
      height: 120px;
    }
    h3 {
      margin-top: 24px;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $primary_500;
      transition: color 250ms ease;
    }
  }
  &.card-small {
    padding: 24px;
    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      color: $primary_500;
      margin-bottom: 16px;
    }
    time {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $black_200;
      margin-bottom: 4px;
    }
    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      color: $black_50;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-height: 78px;
    }
  }
  &.card-dark {
    background: rgba(82, 75, 61, 0.7);
    backdrop-filter: blur(4px);
    &:hover {
      border-color: $primary_300;
      h3,
      h4 {
        color: $primary_300;
      }
    }
  }
  &.card-light {
    background: #fcf3e2;
    &:hover {
      border-color: #d36f0a;
      h3 {
        color: #d36f0a;
      }
    }
  }
  @media screen and (max-width: 768px) {
    &.card-large {
      display: flex;
      align-items: center;
      padding: 20px 16px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 8px;
      }
      h3 {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        br {
          display: none;
        }
      }
    }
  }
}

.not__found {
  background: url('../img/backgrounds/404_circle_background.png') no-repeat center / contain;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 250px);
  min-height: 700px;
  .not__found_block {
    position: relative;
    text-align: center;
    padding: 64px;
    h1 {
      font-size: 36px;
      line-height: 44px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $black_50;
      margin-bottom: 16px;
      position: relative;
    }
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $black_400;
      margin-bottom: 48px;
      position: relative;
    }
    a {
      position: relative;
    }
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      background: #2c271f;
      filter: blur(26px);
      border-radius: 120px;
    }
  }
  @media screen and (max-width: 768px) {
    height: calc(100vh - 500px);
    min-height: 600px;
  }
}
