.homepage {
  padding-bottom: 120px;
  position: relative;
  .homepage__pattern {
    position: absolute;
    bottom: -240px;
    z-index: -1;
    left: -490px;
    width: 2170px;
    height: 1828px;
    background-color: $dark;
    clip-path: polygon(0 0, 1020px 0, 100% 100%, 1020px 90%, 0 0);
    img {
      width: 150%;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 0;
    }
  }
  h2 {
    padding: 16px;
    font-weight: 900;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $black_50;
    background: rgba(82, 75, 61, 0.6);
    backdrop-filter: blur(6px);
    margin-bottom: 56px;
  }
  .homepage__hero {
    position: relative;
    background-color: #201b10;
    .homepage__hero_animation {
      position: absolute;
      top: 0;
      right: calc(50% + 72px);
      width: 694px;
    }
    .homepage__hero_body {
      padding: 272px 0 184px;
      width: 50%;
      margin-left: auto;
      h1 {
        margin-bottom: 32px;
        span {
          color: $primary_500;
        }
      }
      p {
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: $black_200;
        margin-bottom: 56px;
        b {
          font-weight: 600;
          color: $black_100;
        }
      }
    }
  }
  .homepage__craft {
    margin-top: 120px;
    position: relative;
    .homepage__pattern_mobile {
      position: absolute;
      bottom: -870px;
      z-index: -1;
      left: -250px;
      width: 2170px;
      height: 1828px;
      background-color: $dark;
      clip-path: polygon(0 0, 1020px 0, 100% 100%, 1020px 90%, 0 0);
      img {
        width: 150%;
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 0;
      }
    }
    h2 {
      max-width: 536px;
    }
    .homepage__craft_description {
      display: flex;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $black_200;
      b {
        width: 536px;
        flex-shrink: 1;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $black_200;
        margin-right: 24px;
      }
      span {
        width: 536px;
        flex-shrink: 1;
      }
    }
    .homepage__craft_list {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      margin: 40px -24px 0 0;
      li {
        width: calc(33.333333% - 24px);
        margin: 0 24px 40px 0;
        a {
          min-height: 320px;
          height: 100%;
        }
        img {
          width: 120px;
          height: 120px;
        }
        span {
          font-weight: 1000;
          font-size: 28px;
          line-height: 36px;
          text-transform: uppercase;
          display: block;
          margin-top: 16px;
        }
      }
    }
  }
  .homepage__security {
    margin-top: 64px;
    h2 {
      max-width: 760px;
    }
    color: $black_200;
    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      display: block;
      margin-bottom: 40px;
      max-width: 648px;
    }
    b {
      display: block;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      max-width: 648px;
    }
  }

  /* Mobile styles */
  @media screen and (max-width: 1200px) {
    .homepage__craft {
      .homepage__craft_list {
        li {
          width: calc(50% - 24px);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-bottom: 56px;
    h2 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;
    }
    .homepage__pattern {
      display: none;
    }
    .homepage__hero {
      overflow: hidden;
      display: flex;
      align-items: center;
      height: 100vh;
      max-height: 1000px;
      .homepage__hero_animation {
        z-index: 0;
        width: auto;
        height: 100%;
        left: 50%;
        right: auto;
        transform: translateX(-50%) scale(0.9);
        opacity: 0.4;
      }
      .homepage__hero_body {
        width: auto;
        position: relative;
        z-index: 1;
        padding: 0;
      }
    }
    .homepage__craft {
      .homepage__craft_description {
        font-size: 16px;
        flex-direction: column;
        b {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          margin-bottom: 16px;
          width: auto;
          margin-right: 0;
        }
        span {
          width: auto;
        }
      }
      .homepage__craft_list {
        flex-direction: column;
        align-items: stretch;
        margin: 164px 0 0 0;
        li {
          width: auto;
          margin: 0 0 12px 0;
          a {
            min-height: auto;
            img {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
    .homepage__security {
      span {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px;
      }
      b {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
