.icon-arrow_down {
  width: 1.5em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: initial;
}
.icon-icon_git {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-icon_in {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-icon_tw {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: initial;
}
