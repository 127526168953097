.research {
  .research__hero {
    padding-top: 192px;
    padding-bottom: 152px;
    background: url('../../img/patterns/pattern_careers_hero.png') no-repeat center bottom;
    .research__hero_description {
      width: 66.66666%;
      margin-top: 24px;
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $black_400;
      p {
        width: calc(50% - 12px);
      }
    }
    a {
      margin-right: 24px;
    }
  }
  .research__filter {
    margin-top: 64px;
    .wrapper {
      max-width: 1096px;
    }
    .research__filter_tabs {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      button {
        color: $black_300;
        margin-right: 8px;
        border: 2px solid transparent;
        &.contained {
          pointer-events: none;
        }
        &:hover {
          border-color: $primary_300;
          color: $primary_300;
        }
      }
    }
    .research__filter_input {
      max-width: 760px;
      label {
        position: relative;
        width: 100%;
        .icon-search {
          width: 24px;
          height: 24px;
          fill: $black_700;
          position: absolute;
          top: 50%;
          left: 18px;
          transform: translateY(-50%);
        }
        input {
          width: 100%;
          height: 62px;
          background: #e5dccb;
          border-radius: 8px;
          padding: 16px 52px;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: $black_700;
        }
        .research__filter_clear {
          position: absolute;
          right: 16px;
          top: calc(50% - 16px);
          width: 32px;
          height: 32px;
          padding: 0;
          font-size: 0;
          line-height: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          transition: opacity 250ms ease;
          opacity: 0;
          &.show {
            opacity: 1;
          }
          .icon {
            width: 24px;
            height: 24px;
            fill: $black_700;
          }
        }
      }
    }
  }
  .research__no-result {
    max-width: 760px;
    min-height: 480px;
    display: none;
    justify-content: center;
    align-items: center;
    background: url('../../img/patterns/pattern_noresult.svg') no-repeat center / contain;
    font-variation-settings: 'wdth' 145, 'wght' 1000;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $primary_500;
    &.show {
      display: flex;
    }
  }
  .research__articles_list {
    margin-top: 40px;
    .wrapper {
      max-width: 1096px;
    }
    .article {
      background: $black_700;
      border-radius: 4px;
      padding: 24px 32px;
      margin-bottom: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      .article__info {
        display: flex;
        flex-direction: column;
        time {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: $black_200;
          display: block;
          margin-bottom: 4px;
        }
        h3 {
          font-variation-settings: 'wdth' 145, 'wght' 600;
          font-size: 28px;
          line-height: 40px;
          color: $black_50;
          margin-bottom: 32px;
        }
        .article__category {
          font-weight: 700;
          font-size: 18px;
          line-height: 22px;
          text-transform: uppercase;
          color: $primary_500;
          margin-top: auto;
        }
      }
      .article__description {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $black_50;
        max-height: 162px;
        overflow: hidden;
        position: relative;
        p {
          margin-bottom: 16px;
        }
        a {
          color: $primary_500;
        }
        transition: max-height 0.3s ease-out;
        /*&.collapse {
          &.show {
            &::after {
              opacity: 0;
            }
          }
          &:not(.show) {
            min-height: 100px;
            max-height: 100px;
            display: block !important;
          }
        }
        &.collapsing {
          min-height: 100px;
          max-height: inherit;
        }*/
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 216px;
          background: linear-gradient(180deg, rgba(101, 93, 79, 0) 41.67%, #655d4f 100%);
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          pointer-events: none;
          transition: opacity 250ms ease;
        }
      }
      &:hover {
        .article__description {
          transition: max-height 0.3s ease-in;
          max-height: 500px;
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }
  .research__articles_more {
    margin-top: 40px;
    text-align: center;
    button {
      color: $primary_500;
      border: 2px solid transparent;
      transition: opacity 250ms ease;
      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
      &:hover {
        border-color: $primary_300;
        color: $primary_300;
      }
    }
  }
  .research__most-popular {
    margin-top: 120px;
    background-color: #fffdfa;
    padding: 48px;
    .wrapper {
      max-width: 1096px;
    }
    h2 {
      border: 2px solid #2c271f;
      border-radius: 2px;
      padding: 16px;
      font-weight: 1000;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $black_900;
      margin-bottom: 40px;
      max-width: 872px;
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;
      grid-row-gap: 24px;
      li {
        background: #fcf3e2;
        border-radius: 4px;
        color: $primary_500;
        a {
          display: block;
          text-align: center;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: $primary_500;
          padding: 24px 24px 40px 24px;
          border: 2px solid transparent;
          transition: all 250ms ease;
          &:hover {
            color: #d36f0a;
            border-color: #d36f0a;
          }
          b {
            font-weight: 1000;
            font-size: 28px;
            line-height: 36px;
            letter-spacing: 0.01em;
            text-transform: uppercase;

            display: block;
          }
        }
        img {
          width: 120px;
          height: 120px;
          margin-bottom: 24px;
        }
      }
    }
  }

  @media screen and (max-width: 512px) {
    .research__hero {
      .button {
        width: 100%;
        margin-bottom: 16px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .research__hero {
      min-height: 100vh;
      display: flex;
      align-items: center;
      padding: 86px 0;
      background: url('../../img/patterns/pattern_research_mobile.jpg') no-repeat center / cover;
      .research__hero_description {
        display: block;
        width: auto;
        margin-top: 24px;
        p {
          width: auto;
          margin-bottom: 16px;
        }
      }
    }
    .research__filter {
      margin-top: 28px;
      .research__filter_tabs {
        flex-wrap: wrap;
        .button {
          margin-bottom: 8px;
        }
      }
    }
    .research__articles_list {
      .article {
        padding: 20px 16px;
        grid-template-columns: 1fr;
        .article__info {
          h3 {
            font-variation-settings: 'wdth' 145, 'wght' 700;
            font-size: 24px;
            line-height: 36px;
          }
        }
        .article__description {
          max-height: initial;
          &::after {
            opacity: 0;
          }
        }
        /*.article__description {
          &.collapse {
            &.show {
              &::after {
                opacity: 0;
              }
            }
            &:not(.show) {
              max-height: 48px !important;
              display: block !important;
            }
          }
          &.collapsing {
            min-height: 48px !important;
            max-height: initial;
          }
        }*/
      }
    }
    .research__most-popular {
      h2 {
        font-size: 28px;
        line-height: 36px;
        padding: 16px;
      }
      ul {
        display: block;
        li {
          margin-bottom: 12px;
          a {
            display: flex;
            align-items: center;
            padding: 24px 16px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            text-align: left;
            b {
              font-weight: 1000;
              font-variation-settings: 'wdth' 145, 'wght' 1000;
              font-size: 16px;
              line-height: 24px;
            }
            img {
              width: 40px;
              height: 40px;
              margin: 0 8px 0 0;
            }
          }
        }
      }
    }
  }
}
