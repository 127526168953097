.careers {
  .careers__hero {
    padding-top: 192px;
    padding-bottom: 152px;
    background: url('../../img/patterns/pattern_careers_hero.png') no-repeat center bottom;
    .careers__hero_description {
      width: 66.66666%;
      margin-top: 24px;
      margin-bottom: 48px;
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: $black_400;
      p {
        width: calc(50% - 12px);
      }
    }
  }
  .careers__offers {
    padding-top: 120px;
    padding-bottom: 1px;
    background: rgba(30, 26, 18, 0.6);
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100px;
      background: rgba(30, 26, 18, 0.6);
      position: absolute;
      top: -100px;
      left: 0;
      z-index: -1;
    }
    h2 {
      font-weight: 1000;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $black_50;
      margin-bottom: 90px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 108px;
      li {
        width: calc(50% - 124px);
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: $black_200;
        margin-right: 124px;
        h3 {
          font-weight: 1000;
          font-size: 40px;
          line-height: 48px;
          letter-spacing: 0.01em;
          text-transform: uppercase;
          color: $primary_500;
          background: rgba(82, 75, 61, 0.7);
          border-radius: 4px;
          margin-bottom: 32px;
          padding: 56px 16px 16px;
          position: relative;
        }
      }
      &:last-child {
        justify-content: flex-end;
        li {
          margin-right: 0;
          margin-left: 124px;
          h3 {
            text-align: right;
          }
        }
      }
    }
  }
  .careers__question {
    margin-top: 155px;
    &.right {
      h2 {
        margin-left: auto;
        text-align: right;
      }
      ul {
        margin-left: auto;
      }
    }
    h2 {
      margin-bottom: 48px;
      max-width: 872px;
    }
    ul {
      max-width: 872px;
      li {
        padding-left: 60px;
        margin-bottom: 48px;
        position: relative;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: $black_400;
        b {
          font-weight: 700;
          color: $black_50;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $primary_500;
          position: absolute;
          left: 16px;
          top: 16px;
        }
        &::after {
          content: '';
          display: block;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 2px solid $primary_500;
          position: absolute;
          left: 2px;
          top: 2px;
        }
      }
    }
  }
  .careers__apply {
    margin-top: 176px;
    margin-bottom: 120px;
    padding: 120px 0 128px 0;
    background: url('../../img/patterns/pattern_careers_dots.svg') no-repeat center;
    .wrapper {
      max-width: 776px;
      text-align: center;
    }
    h2 {
      font-weight: 1000;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: $black_50;
      margin-bottom: 48px;
    }
  }

  @media screen and (max-width: 1200px) {
    .careers__offers {
      ul {
        li {
          width: calc(50% - 64px);
          margin-right: 64px;
        }
        &:last-child {
          li {
            margin-left: 64px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .careers__hero {
      min-height: 100vh;
      display: flex;
      align-items: center;
      padding: 86px 0;
      background: url('../../img/patterns/pattern_careers_hero_mobile.jpg') no-repeat center bottom / cover;
      .careers__hero_description {
        width: auto;
        display: block;
        p {
          width: auto;
          margin-bottom: 16px;
        }
      }
    }
    .careers__offers {
      h2 {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 32px;
      }
      ul {
        display: block;
        padding: 0;
        margin: 0;
        li {
          width: auto;
          margin: 0 0 24px 0 !important;
          h3 {
            font-size: 28px;
            line-height: 36px;
            padding: 16px;
            margin-bottom: 16px;
            text-align: left !important;
            .circle {
              display: none;
            }
          }
        }
      }
    }
    .careers__question {
      margin-top: 100px;
      h2 {
        font-size: 28px;
        line-height: 36px;
        padding: 16px;
        margin-bottom: 32px;
        text-align: left !important;
      }
      ul {
        li {
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 24px;
          padding-left: 48px;
        }
      }
    }
    .careers__apply {
      margin-top: 72px;
      margin-bottom: 64px;
      padding-top: 76px;
      padding-bottom: 76px;
      h2 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }
  }
}
